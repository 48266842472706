import Link from 'next/link'
import { useAuth } from '@/hooks/auth'
import { Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Layout from '@/components/Layout'
import Head from 'next/head'
import { Controller, useForm } from 'react-hook-form'
import Input from '@/components/Tags/Input'
import { Alert, FormHelperText, InputAdornment } from '@mui/material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import LoadingButton from '@/components/LoadingButton'
import Button from '@/components/Tags/Form/Button'
import Loading from '@/components/Loading'

const defaultValues = {
    email: '',
    password: '',
};


const Login = ({ last_url }) => {
    const router = useRouter();
    const [mainUrl, setMainUrl] = useState(null);

    const { login } = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: last_url ? (last_url == mainUrl+'checkout') ? '/checkout' : '/bookings' : '/bookings',
    })

    const { handleSubmit, reset, setValue, setError, clearErrors, getValues, control, trigger, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: defaultValues
     });

    // const [email, setEmail] = useState('')
    // const [password, setPassword] = useState('')
    const [shouldRemember, setShouldRemember] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [formStatus, setFormStatus] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (router.query.reset?.length > 0 && formError.length === 0) {
            setFormStatus(atob(router.query.reset));
        } else {
            setFormStatus(null);
        }
        const main_url = window.location.protocol + '//' + window.location.host + '/';
        setMainUrl(main_url);
    },[router, formErrors]);

    const onSubmit = async (data) => {
        setLoading(true);
        await login({ ...data, setFormErrors, setFormStatus, remember: shouldRemember });
        if(formStatus) setLoading(false);
        if(formErrors) setLoading(false);
    };

    const onError = (data) => {
        console.log('error', data)
        console.log('error', getValues())
    };

    return (
        <Layout pageTitle='Rapidé - Login'>
            <div className="relative top-0 max-w-full mx-auto lg:mt-0 md:mt-0 sm:mt-[93px] mt-[93px] bg-rapide-black">
                <div className='relative max-w-screen-lg mx-auto md:pt-40 pb-4'>
                    <h1 className='text-3xl text-rapide-yellow tracking-wide text-center'>Login to experience the Rapide Way</h1>
                    <h1 className='text-2xl text-rapide-yellow tracking-wide text-center mb-4'>Online Booking Service</h1>
                    <div className='grid grid-cols-1 place-content-center py-4 px-4 sm:w-96 w-full mx-auto bg-white/95 rounded-md'>
                        {(formStatus == 204) && (
                            <div className='w-full'>
                                <Alert className='mb-4 rounded-md border border-green-200' severity={`success`}>
                                    Successfully login.
                                </Alert>
                                <Loading/>
                            </div>
                        )}
                        <form className={`${formStatus ? 'hidden' : ''}`} onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="grid grid-col-1 gap-2 mb-2">
                                <Controller
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { isTouched, isDirty, error },
                                    }) => {
                                        return(
                                            <Fragment>
                                                <Input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    variant="filled"
                                                    size="small"
                                                    error={errors.email != null}
                                                    helperText={errors.email && errors.email?.message}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: <InputAdornment position="start"><EmailOutlinedIcon/></InputAdornment>,
                                                    }}
                                                />
                                                {formErrors?.email && (
                                                    <FormHelperText className='text-rapide-red text-sm'>{formErrors.email}</FormHelperText>
                                                )}
                                            </Fragment>
                                        )
                                    }}
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: 'Please enter your email address',
                                    }}
                                />
                                <Controller
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { isTouched, isDirty, error },
                                    }) => {
                                        return(
                                            <Fragment>
                                                <Input
                                                    className='w-full'
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    label="Password"
                                                    variant="filled"
                                                    size="small"
                                                    error={errors.password != null}
                                                    helperText={errors.password && errors.password?.message}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: <InputAdornment position="start"><KeyOutlinedIcon/></InputAdornment>,
                                                    }}
                                                />
                                                {formErrors?.password && (
                                                    <FormHelperText className='text-rapide-red text-sm'>{formErrors.email}</FormHelperText>
                                                )}
                                            </Fragment>
                                        )
                                    }}
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: 'Please enter your password',
                                        minLength: {
                                            value: 6,
                                            message: "Didn't minimum length for password"
                                        }
                                    }}
                                />
                            </div>
                            <div className='grid sm:grid-cols-2 grid-cols-1 place-items-center gap-2'>
                                <Link className='text-sm text-blue-600' href="/forgot-password">
                                    Forgot your password?
                                </Link>
                                <Button type="submit" loading={loading} label="Login" llabel="Logging in..." />
                            </div>
                            <div className='pt-4 text-center'>
                                <Link className='uppercase font-semibold text-lg tracking-wide text-blue-600 hover:text-rapide-red' href="/club/sign-up">
                                    Join Rapide Club For Free
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Login

export async function getServerSideProps(context) {
    const last_url =  context.req.headers.referer || null;
    return {
        props: { last_url }, // will be passed to the page component as props
    }
}