import LoadingButton from "@/components/LoadingButton";
import { Fragment } from "react";

export default function Button({type = 'button', classN, onClick, loading, label, llabel, color = 'primary'}){
    return(
        <button
            type={type}
            onClick={onClick}
            disabled={loading}
            className={`shadow inline-flex items-center justify-center leading-6 font-semibold w-full  px-4 py-2 rounded-md border ${(color == 'primary') ? 'text-white bg-rapide-red hover:text-rapide-yellow border-white hover:border-gray-200' : 'text-rapide-red bg-white hover:text-rapide-yellow border-rapide-red hover:border-rapide-red/50'} `+classN}        >
            {loading ? (
                <LoadingButton classN={'w-5 h-5 mr-2'} label={llabel}/>
            ):(
                <Fragment>{label}</Fragment>
            )}
        </button>
    )
}