import { alpha, styled  } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const RedditTextField = styled((props) => (
        <TextField size="small" InputProps={{ disableUnderline: true }} {...props} />
    ))(({ theme, name}) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid',
        borderColor: `${name == 'search' ? '#374151' : '#e2e2e1'}`,
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-disabled':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        },
        '&.Mui-disabled input':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        }
    },
    '& .MuiFormLabel-root': {
        '&.Mui-focused': {
            color: theme.palette.label.sub,
        },
    },
}));


export default function Input({ ...props }){
    return (
        <RedditTextField
            {...props}
        />
    );
}
